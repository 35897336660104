import React from 'react';

import "./global.css";
import Routes from './routes';

function App() {
  const url = window.location.href;

  
  if(url.includes('vichiwork')){
    document.title = 'Vich Wallet';
  }
  else{
    document.title = 'Suri Wallet';
  }
  

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
