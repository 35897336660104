// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Numans);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons{\n    display: flex;\n    gap: 5px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Profile/styles.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,QAAQ;AACZ","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Numans');\n\n.buttons{\n    display: flex;\n    gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
