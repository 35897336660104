// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Numans);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".custom-container{\n    height: 100%;\n    width: 100%;\n    align-content: center;\n    padding: 10px 20px;\n    background-color: #f1f1f1;\n}", "",{"version":3,"sources":["webpack://./src/pages/Home/styles.css"],"names":[],"mappings":"AAEA;IACI,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Numans');\n\n.custom-container{\n    height: 100%;\n    width: 100%;\n    align-content: center;\n    padding: 10px 20px;\n    background-color: #f1f1f1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
